<template>
  <div
    class="bg-center bg-no-repeat bg-cover"
    style="background-image: url('/images/main-bg.jpg')"
  >
    <div class="container py-20 md:py-28">
      <div class="lg:flex">
        <div class="lg:w-1/2 lg:order-2">
          <img
            src="/images/bg-car-wrap.png"
            alt=""
            class="md:w-2/3 lg:w-full md:mx-auto"
          />
        </div>
        <div class="lg:w-1/2 lg:text-left">
          <h1
            class="text-xl font-semibold text-white md:text-2xl txtShadow"
          >
            Looking for One-Stop
          </h1>
          <p class="text-2xl font-bold leading-tight text-white md:text-4xl txtShadow">
            Solution for Commercial Vehicular Wraps & Advertising Printing
            Solutions?
          </p>
          <h1
            class="py-3 mx-auto text-lg font-semibold leading-tight text-gray-800 md:text-xl"
          >
            Most Affordable, Reliable & Efficient Printing Services in Malaysia.
          </h1>

          <!-- cta btn -->
          <div class="pt-6 text-center md:flex md:justify-center lg:justify-start">
            <a href="tel:+60192349778" class="block pb-2 md:pr-1">
              <h1
                class="w-48 py-2 mx-auto font-semibold bg-white rounded-lg shadow-xl text-color-orange"
              >
                Call Us Now!
              </h1>
            </a>
            <a
              href="#"
              id="btn-enquire"
              v-if="!hideEnquiry"
              v-scroll-to="{ el: '#element', duration: 1500 }"
            >
              <h1
                class="w-48 py-2 mx-auto font-semibold bg-white rounded-lg shadow-xl text-color-orange"
              >
                Get A Free Quote!
              </h1>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    hideEnquiry: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style></style>
